@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  font-synthesis: none !important;
}

@layer utilities {
  .collapsible-styles {
    @apply overflow-hidden data-[state=closed]:z-0 data-[state=closed]:animate-slide-up data-[state=open]:animate-slide-down;
  }
}

@font-face {
  font-family: "ABC Diatype Mono";
  src:
    local("mono"),
    url(../public/fonts/ABCDiatypeMono-Bold.woff) format("woff");
}

@font-face {
  font-family: "ABC Diatype";
  src:
    local("ABCDiatype"),
    url(../public/fonts/ABCDiatype-Regular.woff) format("woff");
}
